export const poPageJobSchedulerLiteralsDefault = {
  en: {
    at: 'at',
    back: 'Back',
    next: 'Next',
    periodicity: 'Periodicity',
    execution: 'Execution',
    process: 'Process',
    enterProcess: 'Enter a process',
    recurrent: 'Recurrent',
    single: 'Single',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    scheduling: 'Scheduling',
    parameterization: 'Parameterization',
    conclude: 'Conclude',
    firstExecution: 'First Execution',
    hour: 'Hour',
    day: 'Day',
    weekDays: 'Week days',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    parameters: 'Parameters',
    yes: 'Yes',
    no: 'No',
    notReported: 'Not reported',
    periodicityData: 'Periodicity data',
    confirmation: 'Confirmation',
    confirmSaveMessage: 'Are you sure you want to complete this schedule?',
    confirmUpdateMessage: 'Are you sure you want to update this schedule?',
    saveNotificationSuccessUpdate: 'Successfully updated scheduling',
    saveNotificationSuccessSave: 'Successfully saved scheduling',
    parametersNotFound: 'Parameters not found.'
  },
  es: {
    at: 'a las',
    back: 'Volver',
    next: 'Avanzar',
    periodicity: 'Periodicidad',
    execution: 'Ejecución',
    process: 'Proceso',
    enterProcess: 'Informe un proceso',
    recurrent: 'Recurrente',
    single: 'Única',
    daily: 'Diaria',
    weekly: 'Semanal',
    monthly: 'Mensual',
    scheduling: 'Programación',
    parameterization: 'Parametrización',
    conclude: 'Completar',
    firstExecution: 'Primera ejecución',
    hour: 'Horario',
    day: 'Día',
    weekDays: 'Dias de la semana',
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    parameters: 'Parámetros',
    yes: 'Sí',
    no: 'No',
    notReported: 'No informado',
    periodicityData: 'Dados da periodicidade',
    confirmation: 'Confirmación',
    confirmSaveMessage: '¿Está seguro de que desea completar esta programación de proceso?',
    confirmUpdateMessage: '¿Está seguro de que desea cambiar esta programación de proceso?',
    saveNotificationSuccessUpdate: 'Programación de proceso actualizada con éxito.',
    saveNotificationSuccessSave: 'Programación de proceso salvo con éxito.',
    parametersNotFound: 'No se encontraron parámetros.'
  },
  pt: {
    at: 'às',
    back: 'Voltar',
    next: 'Avançar',
    periodicity: 'Periodicidade',
    execution: 'Execução',
    process: 'Processo',
    enterProcess: 'Informe um processo',
    recurrent: 'Recorrente',
    single: 'Única',
    daily: 'Diária',
    weekly: 'Semanal',
    monthly: 'Mensal',
    scheduling: 'Agendamento',
    parameterization: 'Parametrização',
    conclude: 'Concluir',
    firstExecution: 'Primeira execução',
    hour: 'Horário',
    day: 'Dia',
    weekDays: 'Dias de semana',
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado',
    parameters: 'Parâmetros',
    yes: 'Sim',
    no: 'Não',
    notReported: 'Não informado',
    periodicityData: 'Dados da periodicidade',
    confirmation: 'Confirmação',
    confirmSaveMessage: 'Tem certeza que deseja concluir este agendamento de processo?',
    confirmUpdateMessage: 'Tem certeza que deseja alterar este agendamento de processo?',
    saveNotificationSuccessUpdate: 'Agendamento de processo atualizado com sucesso.',
    saveNotificationSuccessSave: 'Agendamento de processo salvo com sucesso.',
    parametersNotFound: 'Parâmetros não foram encontrados.'
  },
  ru: {
    at: 'в',
    back: 'Назад',
    next: 'Следующий',
    periodicity: 'Периодичность',
    execution: 'Выполнение',
    process: 'Процесс',
    enterProcess: 'Укажите процесс',
    recurrent: 'Периодически',
    single: 'Одиночный',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно',
    scheduling: 'Планирование',
    parameterization: 'Параметризация',
    conclude: 'Завершить',
    firstExecution: 'Первичное выполнение',
    hour: 'Час',
    day: 'День',
    weekDays: 'Рабочие дни',
    sunday: 'Воскресенье',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    parameters: 'Параметры',
    yes: 'Да',
    no: 'Нет',
    notReported: 'Не сообщается',
    periodicityData: 'Данные о периодичности',
    confirmation: 'Подтверждение',
    confirmSaveMessage: 'Вы уверены, что хотите завершить это расписание?',
    confirmUpdateMessage: 'Вы уверены, что хотите обновить это расписание?',
    saveNotificationSuccessUpdate: 'Расписание успешно обновлено',
    saveNotificationSuccessSave: 'Расписание успешно сохранено ',
    parametersNotFound: 'Параметры не найдены'
  }
};
